import * as React from 'react';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Common from './Common';

import Grid from "@mui/material/Grid";

export default function Dokuwiki(props) {
  const [module, setModule] = React.useState({});

  React.useEffect(() => {
    let componentIsMounted = true;
    if (props.society_id) {
      fetch(`/api/societies/${props.society_id}/modules/dokuwiki`)
        .then(res => res.json())
        .then((data) => {
          // Prevent state change when the component is unmounted
          if (componentIsMounted) { setModule(data); }
        })
    }
    return () => { componentIsMounted = false; }
  }, []);

  return (
    <>
        <Common data={module} name='dokuwiki'/>
        <Divider orientation='horizontal' variant='middle' sx={{mt: 2, mb: 1}}/>
        <Typography variant="body2" color="text.secondary">
          Pages
        </Typography>
        <Grid container spacing={2} sx={{mt: 1}}>
        { module?.data?.pages?.map?.((project) => (
          <Grid item lg={4} md={6} xs={12} key={project.id}>
            <a href={project?.web_url}>{project?.name}</a>
          </Grid>
        ))}
        </Grid>
    </>
  );
}
