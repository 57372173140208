import {
  useState,
  useEffect,
} from 'react';

import Typography from "@mui/material/Typography";
import { Container, Box, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import { red, orange, yellow, green } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

function renderIssueTimer(timer){
  const { age, bgcolor, label, count, url } = timer;

  return (
    <Box key={age} sx={{mx: 'auto', textAlign: 'center'}}>
      <Typography variant='overline'>{label}</Typography>
      <Paper elevation={3}  sx={{
        m:1,
        width:128,
        height:128,
        justifyContent: 'center', 
        flexDirection: 'column',
        display: 'flex',
        bgcolor: bgcolor
      }}>
        <Typography align='center' sx={{color: 'grey', fontSize: 50}} >
          { url ? <Link  underline="hover" href={url} sx={{color: 'black'}}>{count}</Link> : count }
        </Typography>
      </Paper>
    </Box>
  )
}

function renderIssueGroup(issueGroup) {
  const timers = [
    { age: 'under_one_day', bgcolor: green[100], label: 'moins de 1 jour' },
    { age: 'under_five_days', bgcolor: yellow[100], label: 'entre 2 & 5 jours' },
    { age: 'under_ten_days', bgcolor: orange[200], label: 'entre 6 & 10 jours' },
    { age: 'more_than_ten_days', bgcolor: red[200], label: 'plus de 10 jours' },
  ];

  const issuesTimers = timers
    .map((timer) => {
      const issues = issueGroup[timer.age];

      return {
        ...timer,
        count: issues?.count || 0,
        url: issues?.url,
      }
    })
    .filter((timer) => Number.isInteger(timer.count))
    .map((timer) => renderIssueTimer(timer))

  return (
    <Grid item key={issueGroup.id} xs="auto" sx={{mx:'auto', my: 2}}>
      <Card sx={{width: 700}}>
        <CardContent>
          <Typography sx={{ flexGrow: 1 }} variant='h5'>{issueGroup.name}</Typography>
          <Box sx={{display: 'flex', flexDirection:'row'}} spacing={2}>
            {issuesTimers}
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default function SupportPage(props) {
  const [issues, setIssues] = useState([]);
  const [error, setError] = useState({});
  useEffect(() => {
    let isMounted = true;

    fetch('/api/support?status_ids=8-1,2')
      .then(res => res.json())
      .then(data => {
        if (isMounted) { 
          setIssues(data);
        }
      })
      .catch((err) => {
          setError(err)
      })
      
    return function cleanup () {
      isMounted = false;
    }
  }, []);
  if (error?.error) {
    return(
      <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
            Il y a eu un problème avec Redmine. Contactez l'administrateur.
        </Alert>
      </Container>

    )
  }
  if (issues.length === 0 ){
    return (
      <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <Typography variant="h2" sx={{mb:3, textAlign: 'center'}}>Tickets inactifs</Typography>
        <Grid container direction='column' spacing={0}  justifyItems='center' alignItems='center'>
          <CircularProgress size={150} sx={{mx:'auto', my: 'auto'}}/>
        </Grid>
      </Container>
    )
  }
  const updatedOnIssues = issues?.updated_on?.map?.((issueGroup) => renderIssueGroup(issueGroup))

  return (
    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
      <Typography variant="h2" sx={{mb:3, textAlign: 'center'}}>Tickets inactifs</Typography>
      
      <Grid container spacing={3} direction='column'>
        {updatedOnIssues}
      </Grid>
    </Container>
  );
}
