import * as React from 'react';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Paper, TableContainer, Table, TableHead, TableBody, TableCell, TableRow} from "@mui/material";
import Link from '@mui/material/Link';

const toTitleCase = (txt) => {
  return txt?.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function displayUserLine(user, url) {

  const username = user.name || user.firstname + ' ' + user.lastname
  const mail = user.mail || user.email
  const userUrl = user.web_url || url + '/' + user.id
  return (
    <TableRow key={user.id}>
            
    <TableCell> <Link  underline="hover" href={userUrl}> {toTitleCase(username)} </Link></TableCell>
    <TableCell> {mail} </TableCell>
  </TableRow>
  )
}

export default function UsersList(props) {
  const module = props.data
  const users = module?.data?.users ? module?.data?.users : module?.users;

  const sortByName = (a, b) => {
    let name1 = a?.name?.toLowerCase?.();
    let name2 = b?.name?.toLowerCase?.();
    return name1 < name2 ? -1 : 1;
  }

  return (
    <>
        <Divider orientation='horizontal' variant='middle' sx={{mt: 2, mb: 1}}/>
        <Typography variant="body2" color="text.secondary">
            Utilisateurs
        </Typography>
        <TableContainer component={Paper} sx={{mb:3, mt:2}}>
          <Table >
          <TableHead>
            <TableRow>
              <TableCell >
                Nom complet
              </TableCell>

              <TableCell>
                Mail
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {users?.sort(sortByName).map?.((user) => (
            displayUserLine(user, props.url)
          ))}           
          </TableBody>
          </Table>
        </TableContainer>
    </>
  );
}
