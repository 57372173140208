import * as React from "react";
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Fab from '@mui/material/Fab';

import Paper from '@mui/material/Paper';
import StyledLink from './StyledLink';
import { Typography } from "@mui/material";

import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddNewSociety from "./AddNewSociety";

function Societies({ societies })  {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setOpen(false)};
  const [societyCreated, setSocietyCreated] = React.useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sortByName = (a, b) => a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1

  if (societies.message) {
    return (
      <Grid sx={{mt: 4, mb: 4}}>
        <div>Pas de données à afficher</div>
      </Grid>
    )
  }

    return (
      <Container>
        <Collapse in={Boolean(societyCreated)}>
          <Alert
            severity="success"
            variant="outlined"
            sx={{ my: 1 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => { setSocietyCreated(null)}}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            La société {societyCreated?.name} a été créée. <StyledLink to={`/societies/${societyCreated?.id}`}>Voir la société</StyledLink>
          </Alert>
        </Collapse>
        <AddNewSociety open={open} handleClose={handleClose} setSocietyCreated={setSocietyCreated}/>

        <Stack>
          <Grid container sx={{ my: 2 }} alignItems="center">
            <Grid item flexGrow={1}>
              <Typography variant='h4'>
                Liste des sociétés
              </Typography>
            </Grid>

            <Grid item>
              <Fab
                color="primary"
                size="small"
                onClick={handleOpen}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 500 }} align="left">Nom</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {societies
                  ?.sort?.(sortByName)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((society) => (
                  <TableRow
                    key={society.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="left">
                      <StyledLink to={`/societies/${society.id}`}>{society.name}</StyledLink>
                    </TableCell>
                    <TableCell align="left">{society.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={societies?.length||0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Container>
    )
}

export default Societies;