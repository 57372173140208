import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

export default function IssuesCreationResult(props) {
    const issuesResult = props.result;
    const successIssues = issuesResult.filter((result) => result.status === 'success');
    const errorIssues = issuesResult.filter((result) => result.status !== 'success');
    return (
        <Stack spacing={1}>
        { successIssues.length > 1 ? 
            (<Alert severity='success'> {successIssues.length} issues ont été créées avec succès</Alert>) :
            ( successIssues.length === 0 ? 
                (<Alert severity='warning'>Aucune issue créée</Alert>) : 
                (<Alert severity='success'> {successIssues.length} issues a été créée avec succès</Alert>) 
            )
        }
        {errorIssues.map((result) => 
            <Alert severity={result.status}>
                L'issue n'a pas été créée pour le projet {result.project_name} car {result.description?.error}
            </Alert>
        )}
        </Stack>
    )
}
