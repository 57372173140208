import * as React from 'react';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import Modules from './Modules';
import Contact from './Contact';
import { Grid } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Paper from '@mui/material/Paper';
import { blue } from '@mui/material/colors';
import Alert from '@mui/material/Alert';

const renderStatus = (status) => {
  if (status) {
    return ( 
      <>
        <Grid item>
          <WarningIcon sx={{width:23, height:23, color:"red"}}/>
        </Grid>
        <Grid item>
            <Typography color="red" variant="button">
            Paiement en retard
          </Typography>
        </Grid>
      </>
    )
  } else {
    return(
      <>
        <Grid item>
          <CheckCircleOutlineRoundedIcon sx={{m:0, p:0, width:23, height:23, color:"green"}} />
        </Grid>
        <Grid item>
            <Typography color="green" variant="button" >
            Aucun paiement en retard
          </Typography>
        </Grid>
      </>
    )
  }
}

export default function Society(props) {
  const { data: society, contacts } = props
  const [status, setStatus] = React.useState(false)
  const refreshSociety = props.refreshSociety;
  
  return (
    <>
      <Grid container>
        <Grid item xs={9} md={9} lg={9}>
          <Typography variant="body1" color="text.secondary">
            Description
          </Typography>
          <Typography variant="body2" color="text.secondary">
            { society?.description }
          </Typography>
        </Grid>
        <Grid item xs={3} md={3} lg={3} sx={{}} container>
          <Paper sx={{ p: 2, margin: 'auto', maxWidth: 500, flexGrow: 1 }}>
            <Grid item xs={12} container spacing={1}>
              { renderStatus(status) }
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Divider orientation='horizontal' variant='fullwidth' sx={{mt: 2, mb: 2}}/>
      <Grid container>
        <Typography variant="body1" color="text.secondary">
            Contacts
        </Typography>
        <Grid container>
          { contacts.length === 0 || contacts?.code === 404 ? 
          (<Alert severity="info" variant='filled'sx={{mb:1, bgcolor: blue[300]}}>Il n'y a pas de contacts associés à cette société.</Alert>) : 
          (contacts?.map?.((contact, index) => (
              <Grid item key={contact.id}>
                <Contact data={contact} />
              </Grid>
          )))}
        </Grid>
      </Grid>
      <Divider orientation='horizontal' variant='fullwidth' sx={{mt: 2, mb: 1}}/>
      <Grid container>
        <Modules modules={society?.modules} society_id={society.id} setStatus={setStatus} refreshSociety={refreshSociety}/>
      </Grid>
    </>
  );
}
