import * as React from 'react';
import TextField from '@mui/material/TextField';
import { styled, alpha } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import throttle from 'lodash/throttle';
import { useNavigate } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import {InputAdornment} from "@mui/material";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-root': {
    backgroundColor: alpha(theme.palette.common.white, 0.8),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.9),
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export default function Search() {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const navigate = useNavigate()

  const performSearch = React.useMemo(
    () =>
      throttle(({ input }) => {
        const searchParams = new URLSearchParams({ q: input })
        fetch(`/api/societies?${searchParams.toString()}`, { credentials: 'include' })
          .then(res => res.json())
          .then(results => {
            if (Array.isArray(results)) { setOptions(results) }
          })
      }, 500),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    performSearch({ input: inputValue }, (results) => {
      if (active) {
        setOptions(Array.isArray(results) ? results : []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, performSearch]);

  return (
    <Autocomplete
      id="search-field"
      sx={{ width: 300 }}
      value={value}
      options={options}
      noOptionsText={inputValue ? 'Pas de résultats' : 'Rechercher une société'}
      filterOptions={(x) => x}
      blurOnSelect
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        if (newValue?.id) {
          navigate(`/societies/${newValue?.id}`)
        }
        setValue(null);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          placeholder="Rechercher..."
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
