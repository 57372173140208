import * as React from 'react';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import {Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer} from "@mui/material";
import Link from '@mui/material/Link';
import TablePagination from '@mui/material/TablePagination';

const renderOrder = (order) => {
  const deliveryDate = new Date(order?.delivery_date * 1000);
  const creationDate = new Date(order?.date * 1000);

  return (
    <TableRow key={order?.id}>
      <TableCell align="center">
      <Link underline="hover" href={`${process.env.REACT_APP_DOLIBARR_URL}/commande/card.php?id=${order?.id}`}>
        {order?.ref}
      </Link>
      </TableCell>
      <TableCell align="center"> {creationDate.toLocaleDateString()}</TableCell>
      <TableCell align="center"> {deliveryDate.toLocaleDateString() === "01/01/1970" ? "Pas de date." : deliveryDate.toLocaleDateString()}</TableCell>
      <TableCell align="center"> {order?.statut}</TableCell>
    </TableRow>
  );
}

export default function Orders(props) {
  const { orders } = props;
  let ordersList;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const sortByDate = (a, b) => a?.date < b?.date ? 1 : -1

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  if (orders.length > 0 ) {
    ordersList = orders
    ?.sort?.(sortByDate)
    ?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    ?.map?.(order => renderOrder(order))
  }
  else {
    ordersList = <TableRow><TableCell align="center" colSpan={3}>Aucune commande en cours</TableCell></TableRow>
  }
  return (
    <>
      <Typography variant="body1" color="text.secondary">
        Commandes
      </Typography>
      <TableContainer component={Paper} sx={{mb:3, mt:2}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Nom</TableCell>
            <TableCell align="center">Date de création</TableCell>
            <TableCell align="center">Date de livraison</TableCell>
            <TableCell align="center">Statut</TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
          {ordersList}
      </TableBody>
      </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={orders?.length||0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Nombre de lignes par page'
        />
      <Divider orientation='horizontal' variant='fullwidth' sx={{mt: 2, mb: 1}}/>
    </>
  );
}
