import { Link } from 'react-router-dom';
import { styled } from '@mui/system';


export default styled(Link)`
    text-decoration: none;

    &:hover {
        text-decoration: underline;
        color: inherit;
    }

    color: #009be5;

`;

