import { useState, useEffect, useRef, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from "@mui/material/Stack";
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

export default function EntitySelect(props) {
    const module = props.module;
    const [availableEntities, setAvailableEntities] = useState([]);
    const [open, setOpen] = useState(false);

    const [availableEntitiesError, setAvailableEntitiesError] = useState(null);
    const isMounted = useRef(false);
    const [alert, setAlert] = useState(null)
    const loading = open && availableEntities.length === 0;

    useEffect(() => {
        isMounted.current = true;
        
        return function cleanup() {
          isMounted.current = false;
        };
      }, []);
      
      const handleOpen = async () => {
        setOpen(true);
        try {
          const res = await fetch(`/api/modules/${module.id}/entities`);
          const data = await res.json();
          if (isMounted.current) {
            setAvailableEntities(data);
          }
        } catch(err){
          setAvailableEntitiesError(err)
          setAlert(err?.message)
        }

    }


    return (
      <Stack>
        <Collapse in={Boolean(alert)}>
          <Alert
            severity="error"
            variant="outlined"
            sx={{ py: 0, mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => { setAlert(null); }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert}
          </Alert>
        </Collapse>

        <Autocomplete
          {...props.field}
          open={open}
          onOpen={() => {
            handleOpen();
          }}
          onClose={() => {
            setOpen(false);
          }}
          loading={loading}
          value={props.field.value || null}
          options={availableEntities}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label={`Entité dans ${module?.name}`} 
              InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            />
          )}
          onChange={(e, newValue) => props.field.onChange(newValue)}
        />
      </Stack>
    )
}