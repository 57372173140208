import {
  useState,
  useEffect,
} from 'react';
import {useParams } from "react-router-dom";
import Society from '../../components/Society';
import StyledLink  from '../../components/StyledLink';

import Container from "@mui/material/Container";
import {Breadcrumbs} from "@mui/material";
import Typography from "@mui/material/Typography";
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';

export default function SocietyPage(props) {
  const [society, setSociety] = useState({});
  const [contacts, setContacts] = useState([]);
  const [societyError, setSocietyError] = useState(null);
  const [contactsError, setContactsError] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    let isMounted = true;

    refreshSociety(isMounted);

    fetch(`/api/modules/dolibarr/contacts?thirdparty_id=${id}&statut=1`)
      .then(res => res.json())
      .then(data => {
        if (isMounted) {
          if (data?.error && data?.error?.code !== 404) {
            setContactsError(data)
          }
          else if (data?.error && data?.error?.code === 404){
            setContacts([])
          }
          else {
            setContacts(data)
          }
        }
      })
      .catch((err) => {
        setContactsError(err)
      });

    return function cleanup () {
      isMounted = false;
    }
  }, [id]);

  const refreshSociety = (isMounted) => {
    fetch(`/api/societies/${id}`)
    .then(res => res.json())
    .then(data => {
      if (isMounted) { setSociety(data); }
    })
    .catch((err) => {
      setSocietyError(err)
    });
  }

  return (
    <Container sx={{my:2, px:0}}>
      <Breadcrumbs aria-label="breadcrumb" sx={{mb: 3}}>
        <StyledLink underline="hover" color="inherit" to="/">Sociétés</StyledLink>
        <Typography color="text.primary">{society?.name}</Typography>
      </Breadcrumbs>
      {societyError || !society ?  (
        <Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
          Il y a eu un problème avec le chargement de la société. Contactez l'administrateur.
        </Alert>
      ) : null }
      { contactsError ? (
        <Alert severity="error" variant='filled'  sx={{mb:1, bgcolor: red[300]}}>
          Il y a eu un problème avec le chargement des contacts de la société. Contactez l'administrateur.
        </Alert>
      ) : (null) }
      {society ? (
        <Society data={society} contacts={contacts} refreshSociety={refreshSociety}/>
        ) : null
      }
    </Container>
  );
}
