import * as React from 'react';

import {Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function ProjectSelector(props) {
    const {
      availableProjects,
      selected: selectedProjects,
      setSelectedProjects,
    } = props;
    const isSelected = (project) => selectedProjects.some((p) => p?.id === project?.id);
    let allSelected = availableProjects.every(project => isSelected(project));

    const toggleProject = (project) => {
      if (isSelected(project)) {
        setSelectedProjects(selectedProjects.filter((p) => p?.id !== project?.id));
      } else {
        setSelectedProjects([...selectedProjects, project]);
      }
    };

    const toggleAll = () => {

      if (allSelected) {
        setSelectedProjects([]);
      } else {
        setSelectedProjects(availableProjects.slice());
      }
    };
    
    return (
        <Grid container>
            <Grid item>
                <Button key="toggleProjects" onClick={toggleAll}>{ allSelected ? "Décocher tous" : "Cocher tous"}</Button>
                <Box sx={{ display: 'flex' }}>
                <FormControl component="fieldset" variant="standard">
                    <FormGroup row>
                        {availableProjects.map((project) =>(
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={project.id}
                                  id={`project-${project.id}`}
                                  checked={isSelected(project)}
                                />
                              }
                              onChange={() => toggleProject(project)}
                              label={project.name}
                              key={project.id}
                            />
                        ))}
                    </FormGroup>
                </FormControl>
            </Box>
            </Grid>
        </Grid>)
}