
import {useState, useEffect, useRef} from 'react';
import Typography from "@mui/material/Typography";
import { Container, Box, Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import SendIcon from '@mui/icons-material/Send'
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ProjectSelector from '../components/support/ProjectSelector';
import SupportSelector from '../components/support/SupportSelector';
import IssuesCreationResult from '../components/support/IssuesCreationResult';
import IssuesType from '../components/support/IssuesType';
import StepContent from '@mui/material/StepContent';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';

export default function MessengerPage(props) {
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [availableProjects, setAvailableProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [supportType, setSupportType] = useState('4420');
    const [trackerType, setTrackerType] = useState(3);
    const [status, setStatus] = useState(4);
    const [parentId, setParentId] = useState(4);
    const [priority, setPriority] = useState(4);
    const [assignedTo, setAssignedTo] = useState(12);
    const [activeStep, setActiveStep] = useState(0);
    const [issuesResult, setIssuesResult] = useState([]);

    const [submitting, setSubmitting] = useState(false);
    const isMounted = useRef(false);    
    const [issuesError, setIssuesError] = useState(null);
    const [projectsError, setProjectsError] = useState(null);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      isMounted.current = true;

      return function cleanup () {
        isMounted.current = false;
      };
    }, []);

    const updateAvailableProjects = () => {
      setLoadingProjects(true);
      
      setAvailableProjects([]);
      setSelectedProjects([]);

      fetch(`/api/projects-id?support_product=${supportType}`)
        .then(res => res.json())
        .then((data) => {
          if (isMounted.current) {
            setAvailableProjects(data);
            setSelectedProjects(data);
          }
        })
        .catch((err) => { 
          setProjectsError(err);
          setDisabled(true);
        })
        .finally(() => {
          if (isMounted.current) {
            setLoadingProjects(false);
          }
        });
    };

    const handleNext = () => {
      if (activeStep === 0) {
        updateAvailableProjects();
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
      setActiveStep(0);
      setSubject('');
      setContent('');
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      setSubmitting(true);
      const requestOptions = {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
              projects_id: selectedProjects,
              subject: subject,
              description: content,
              tracker_id: trackerType,
              status_id: status,
              parent_id: parentId,
              priority_id: priority,
              assigned_to_id: assignedTo,
          })
      }
      fetch("/api/messenger", requestOptions)
        .then(res => res.json())
        .then((data) => setIssuesResult(data.issues_created))
        .catch((err) => setIssuesError(err))
        .finally(() => { 
          if (isMounted.current){
            setSubmitting(false);
            handleNext();
          }
        })
    };

    const steps = [
        { 
          label: 'Choisir le type de support' ,
          content : (<SupportSelector supportType={supportType} setSupportType={setSupportType} />)
        },
        { 
            label: 'Choisir les projets' ,
            content : projectsError ? (<Alert severity="error"> Oups ! Une erreur est survenue. Contactez l'administrateur. </Alert>): (loadingProjects ? <CircularProgress /> : (
              <ProjectSelector
                availableProjects={availableProjects}
                selected={selectedProjects}
                setSelectedProjects={setSelectedProjects}
              />)
            )
        },
        {
            label: 'Définir le type d\'issue',
            content : (
              <IssuesType
                trackerType={trackerType}
                status={status}
                priority={priority}
                parentId={parentId}
                assignedTo={assignedTo}

                setTrackerType={setTrackerType}
                setStatus={setStatus}
                setPriority={setPriority}
                setParentId={setParentId}
                setAssignedTo={setAssignedTo}
              />
            )
        }, 
        {
            label:'Écrire le message et son sujet',
            content : (<Box component='form' id='message-form' onSubmit={handleSubmit}>
            <Grid container  direction="column" justifyContent="center" alignItems="center">
                <FormControl fullWidth>
                    <TextField label='Sujet' name='subject' required sx={{my:1}} onInput={(event) => setSubject(event.target.value)}/>
                    <TextField label='Message' name='description' required sx={{my:1}} multiline rows={12} onInput={(event) => setContent(event.target.value)}/>
                </FormControl>
            </Grid>
        </Box>)
        },
        {
          label: 'Affichage des résultats',
          content : issuesError ? (<Alert severity="error">Oups ! Une erreur est survenue. Contactez l'administrateur. </Alert>) : (<IssuesCreationResult result={issuesResult}/>)
        }
    ];

const isLastStep = activeStep === steps.length - 2;

return (
    <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
    <Typography variant="h2" sx={{mb:3, textAlign: 'center'}}>Messagerie</Typography>
    <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((object, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={object.label} {...stepProps}>
              <StepLabel {...labelProps}>{object.label}</StepLabel>
              <StepContent>{object.content}</StepContent>
            </Step>
          );
        })}
      </Stepper>

    {activeStep === steps.length -1 ? (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Recommencer</Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Précédent
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />

            {
              isLastStep ? (
                <Button
                  variant="contained"
                  type="submit"
                  form="message-form"
                  endIcon={submitting ? <CircularProgress size={20} /> : <SendIcon />}
                  key="submitButton"
                  disabled={submitting}
                >
                  Envoyer
                </Button>
              ) : (
                <Button 
                  key="nextButton" 
                  onClick={handleNext} 
                  disabled={disabled}
                >
                  Suivant
                </Button>
              )
            }
          </Box>
        </>
      )}
    </Container>
);
}
