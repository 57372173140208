import * as React from 'react';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import {Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer} from "@mui/material";
import Link from '@mui/material/Link';
import TablePagination from '@mui/material/TablePagination';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const renderContract = (contract) => {
  const sortedDates = contract?.lines
    ?.filter(line => Number.isInteger(line.date_end))
    ?.map?.(line => new Date(line.date_end * 1000))
    ?.sort((a, b) => a.getTime() < b.getTime() ? -1 : 1);

  const servicesCounter = contract?.lines.reduce((acc, item) => {
    
    const counter = acc.get(item.ref);

    if (counter?.count) {
      counter.count += 1;
    } else {
      acc.set(item.ref, { count: 1, item });
    }
    return acc;
  }, new Map());

  const services = Array.from(servicesCounter.entries()).map(([service, item]) => (<ListItem key={service}>{item.count} service(s) {service} ({item?.item?.product_label})</ListItem>))
  const now = Date.now();
  const highestExpirationDate = sortedDates?.[sortedDates?.length - 1];
  const nextExpirationDate = sortedDates?.find(expDate => expDate.getTime() > now);
  return (
    <TableRow key={contract.id}>
      <TableCell align="center"> 
        <Link underline="hover" href={`${process.env.REACT_APP_DOLIBARR_URL}/contrat/card.php?id=${contract?.id}`}>
          {contract.ref}
        </Link>
      </TableCell>
      <TableCell align="center"><List>{services}</List></TableCell>
      <TableCell align="center"> { `${nextExpirationDate?.toLocaleDateString() || ''}` }</TableCell>
      <TableCell align="center"> { `${highestExpirationDate?.toLocaleDateString() || ''}` }</TableCell>
      <TableCell align="center"> { renderStatus(sortedDates) }</TableCell>
    </TableRow>
  );
}


function renderStatus(sortedDates) {
    const now = Date.now();

    const expiredDates = sortedDates?.filter(expDate => expDate.getTime() < now);
    const maxDate = sortedDates?.[sortedDates?.length - 1];
    const daysBeforeContractEnd = Math.floor((maxDate?.getTime() - now) / (24*60*60*1000));
    
    if (daysBeforeContractEnd <= 0){
      return <Typography color="red">Contrat expiré</Typography>
    }
    if (daysBeforeContractEnd <= 45){
      return <Typography color="orange">Le contrat expire dans {daysBeforeContractEnd} jour(s)</Typography>
    }
    if (expiredDates?.length > 0){
      return <Typography color="green">Le contrat est en cours. ({expiredDates?.length} service(s) expiré(s))</Typography>
    }
    
    return <Typography color="green">Le contrat est en cours.</Typography>
}

export default function Contracts(props) {
  const { contracts } = props;
  let contractsList;
  const openContracts = contracts.filter((contract) => contract.nbofservices !== contract.nbofservicesclosed)
  const closeContracts = contracts.filter((contract) => contract.nbofservices === contract.nbofservicesclosed)
  
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const sortByDate = (a, b) => a?.date < b?.date ? 1 : -1

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  if (contracts?.length > 0 ) {
    contractsList = openContracts
      ?.sort?.(sortByDate)
      ?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      ?.map?.((contract) => renderContract(contract))
  }
  else {
    contractsList = <TableRow><TableCell align="center" colSpan={5}>Aucun contrat en cours</TableCell></TableRow>
  }

  return (
    <>
      <Typography variant="body1" color="text.secondary">
        Contrats
      </Typography>
      <TableContainer component={Paper} sx={{mb:3, mt:2}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Nom</TableCell>
            <TableCell align="center">Services</TableCell>
            <TableCell align="center">Prochaine fin de service</TableCell>
            <TableCell align="center">Fin de contrat</TableCell>
            <TableCell align="center">Statut</TableCell>
          </TableRow>
        </TableHead>
      <TableBody>
          {contractsList}
      </TableBody>
      </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={contracts?.length||0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage='Nombre de lignes par page'
      />
      <Divider orientation='horizontal' variant='fullwidth' sx={{mt: 2, mb: 1}}/>
    </>
  );
}
