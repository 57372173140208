import * as React from 'react';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Common from './Common';
import DifferencesList from './Differences';

import Link from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Alert from '@mui/material/Alert';
import UsersList from "./UsersList";
import { red } from '@mui/material/colors';
import Box from "@mui/material/Box";


export default class Gitlab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      module: {},
      differencesStatus: {},
      projectError: null
    }
  }



  getDifferences(societyId){
    fetch(`/api/societies/${societyId}/modules/gitlab/update`)
    .then(res => res.json())
    .then((data) => {
        // Prevent state change when the component is unmounted
        this.setState({
          differencesStatus: data
        })
      })    
      .catch((err) => {
        this.setState({
          differencesError: err
        })
      })   
  }

  componentDidMount() {
    this._isMounted = true
    const { props } = this;

    if (props.societyId) {
      this.refreshModuleData(props);
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  refreshModuleData(opt){
    const societyId = this.props.societyId;
    const refresh = opt?.refresh;

    fetch(`/api/societies/${societyId}/modules/gitlab${refresh ? '?refresh=true' : ''}`)
    .then(res => res.json())
    .then((data) => {
      // Prevent state change when the component is unmounted
      if (!this._isMounted){ return; }  
      this.setState({ module: data })
      this.getDifferences(societyId)
      
    })
    .catch((err) => {
      this.setState({
        projectError: err
      })
    })
  }

  componentDidUpdate(previousProps) {
    if (this.props.societyId !== previousProps?.societyId) {
      this.refreshModuleData(this.props);
    }
  }

  render(){
    const {
      module,
      differencesStatus,
      differencesError,
      projectError,
    } = this.state

    const head = (
      <Box sx={{display: 'grid',gridAutoColumns: '1fr',gap: 1}}>
        <Box item  sx={{ gridRow: '1', gridColumn: 'span 3' }}>
          <Common module={module} societyId={this.props.societyId} name='gitlab' onUpdate={() => { this.refreshModuleData({ refresh: true }) }}/>
        </Box>
      </Box>
    )
    if (projectError) {
      return (<>
        {head}
        <Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
          {projectError}
        </Alert>
      </>)
    }
    return (
      <>
        <Grid container >
          <Grid item  lg={12} md={12} xs={12}>
            <Common module={module} societyId={this.props.societyId} name='gitlab' onUpdate={() => {this.refreshModuleData()}}/>
          </Grid>
          {differencesStatus.error || differencesError?.error ? 
          (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
            Il y a eu un problème avec le chargement des différences avec Dolibarr, de la société. Contactez l'administrateur.
          </Alert>) : 
          (<Grid item lg={12} md={12} xs={12} sx={{m:1}}>
            <DifferencesList society={differencesStatus}  module='Gitlab'/>
          </Grid>)
        }
        </Grid>
        <UsersList data={module} url={`${process.env.REACT_APP_GITLAB_URL}/`}/>
        <Divider orientation='horizontal' variant='middle' sx={{mt: 2, mb: 1}}/>
        <Typography variant="body2" color="text.secondary">
          Projets
        </Typography>
        <Grid container spacing={2}>
        {module?.error || projectError?.error ? 
            (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
              Il y a eu un problème avec le chargement des projets de la société. Contactez l'administrateur.
            </Alert>) : 
            (module?.data?.projects?.map?.((project) => (
              <Grid item lg={2} md={2} xs={2} key={project.id}>
                <Link underline="hover" href={project?.web_url}>{project?.name}</Link>
              </Grid>))
            )
          }
        </Grid>
      </>
    );
  }
}
