import {
  useState,
  useEffect,
  useRef,
} from 'react';
import Societies from '../components/Societies';
import { Container, Alert } from '@mui/material';
import { red } from '@mui/material/colors';

export default function SocietiesPage(props) {
  const [pageData, setPageData] = useState({});
  const [error, setError] = useState({});
  let mounted = useRef(true);

  useEffect(() => {
    
    fetch('/api/societies', { credentials: 'include' })
      .then(res => res.json())
      .then(data => {
        if (mounted.current) {
          setPageData(data);
        }
      })
      .catch((err) => {
        setError(err)

      });
      
    return function cleanup () {
      mounted.current = false;
    }
  }, []);

  if (error?.message) {
    return(
      <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <Alert severity="error" variant='filled'  sx={{mb:1, bgcolor: red[300]}}>
            Il y a eu un problème avec le chargement des données. Contactez l'administrateur.
        </Alert>
      </Container>

    )
  }
  return <Societies societies={pageData} />;
}

