import * as React from 'react';

import GroupIcon from '@mui/icons-material/Group';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import List from '@mui/material/List';
import StyledLink  from './StyledLink';
import SearchBar from './Search';
import { ListSubheader } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import BugReportIcon from '@mui/icons-material/BugReport';

const categories = [
  {
    id: 'Global',
    children: [
      { id: 'Sociétés', icon: <GroupIcon />, link:'/'},
    ],
  },
  {
    id: 'Support',
    children: [
      { id: 'Tickets inactifs', icon: <BugReportIcon />, link: '/support' },
      { id: 'Messagerie', icon: <EmailIcon />, link: '/messenger' },
      
    ]
  }

];

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function DrawerTheme(props){
  const { ...other } = props;

    return(
      <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          {props.name}
        </ListItem>
        <ListItem>
          <SearchBar/>
        </ListItem>
        <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Profile (WIP)</ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id}>
          <List sx={{ py: 1, px: 1 }}>
              <ListSubheader sx={{bgcolor: 'inherit', color: 'white'}}>{id}</ListSubheader>
            <List>
            {children.map(({ id: childId, icon, active, link }) => (
              <StyledLink to={link} key={childId} >
                <ListItem disablePadding>
                  <ListItemButton selected={active} sx={item}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
                </StyledLink>
            ))}
            </List>
            </List>
          </Box>
        ))}
      </List>
    </Drawer>
  );
}

