import * as React from 'react';
import Toolbar from "@mui/material/Toolbar";
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const lightColor = 'rgba(255, 255, 255, 0.7)';

export default function AppTheme(props){
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  const toTitleCase = (txt) => {
    return txt?.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  const clearCache = () => {
    fetch(`/api/cache`)
    .then(res => res.json())
    .then((data) => {})
  }

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={0}>
        <Toolbar>
          <Grid container spacing={2} alignItems="center" sx={{mt:0.5, mb:2}}>
            <Grid item xs/>
            <Grid item>
              <Button
                    sx={{ borderColor: lightColor }}
                    variant="outlined"
                    color="inherit"
                    xs={1} md={2} lg={2}
                    onClick={clearCache}
                >            
                Vider cache
              </Button>
              </Grid>
              <Grid item>
                <Chip icon={<AccountCircle/>} color="primary" size="medium" sx={{ml: 1, mr:0}} label={toTitleCase(props.username)}/>
                <Button
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? 'composition-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  <MoreVertIcon color="secondary" size="medium" sx={{ml:0}}/>
                </Button>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom-start' ? 'left top' : 'left bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem onClick={(e) => {
                              e.preventDefault();
                              window.location.href='https://auth.beezim.fr/cas/logout';
                            }}>Déconnexion</MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            </Grid>
          </Toolbar>
      </AppBar>

    </React.Fragment>
  );
}

