import * as React from 'react';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer } from "@mui/material";
import TablePagination from '@mui/material/TablePagination';
import Link from '@mui/material/Link';

const renderInvoice = (invoice) => {
  const now = new Date()
  const invoicePaymentLimit = new Date(invoice?.date_lim_reglement * 1000);
  const isPaid = (invoice?.remaintopay === "0");

  let invoiceStatus;

  if (isPaid) {
    invoiceStatus = <Typography color="green">Payé</Typography>
  } else if (invoicePaymentLimit <= now) {
    invoiceStatus = <Typography color="red">Paiement en retard</Typography>
  } else {
    invoiceStatus = <Typography color="orange">Paiement en attente</Typography>
  }


  return (
    <TableRow key={invoice?.id}>
      <TableCell align="center">
        <Link underline="hover" href={`${process.env.REACT_APP_DOLIBARR_URL}/compta/facture/card.php?facid=${invoice?.id}`}>
        {invoice?.ref}
        </Link>
      </TableCell>
      <TableCell align="center">{invoicePaymentLimit.toLocaleDateString()}</TableCell>
      <TableCell align="center">{invoiceStatus}</TableCell>
    </TableRow>
  )
}


export default function Invoices(props) {
  const { invoices } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const sortByDate = (a, b) => a?.date < b?.date ? 1 : -1

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  let invoicesList;
  if (invoices.length > 0) {
    invoicesList = invoices
      ?.sort?.(sortByDate)
      ?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      ?.map?.((invoice) => renderInvoice(invoice))
  }
  else {
    invoicesList = <TableRow><TableCell align="center" colSpan={3}>Aucune facture en cours</TableCell></TableRow>
  }
  return (
    <>
      <Typography variant="body1" color="text.secondary">
        Factures
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 3, mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Nom</TableCell>
              <TableCell align="center">Date limite de règlement</TableCell>
              <TableCell align="center">Statut</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoicesList}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={invoices?.length||0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage='Nombre de lignes par page'
        />
      <Divider orientation='horizontal' variant='fullwidth' sx={{ mt: 2, mb: 1 }} />
    </>
  );
}
