import { useState, useEffect, useRef } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import EntitySelect from './EntitySelect';
import TextField from '@mui/material/TextField';
import { useForm, Controller, useFormState } from "react-hook-form";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 800,
  width: '90%',
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 2,
};

export default function AddNewSociety(props) {
  const setSocietyCreated = props.setSocietyCreated;
  const [alert, setAlert] = useState(null)
  const isMounted = useRef(false);
  const [modules, setModulesList] = useState([]);
  const [modulesError, setModulesError] = useState(null);
  const {
    reset,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      modules: {}
    }
  });

  const { isSubmitting } = useFormState({control});
  
  useEffect(() => {
    isMounted.current = true;

    
    return function cleanup() {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.open) {
      reset();
      setAlert(null);
      fetch('/api/modules')
      .then(res => res.json())
      .then(data => {
        if (isMounted.current) { setModulesList(data); }
      })
      .catch(err => {
        if (isMounted.current) { setModulesError(err); }
      });

    }
  }, [props.open]);

  const onSubmit = (data) => {
    const fields = {};

    Object.entries(data.modules).forEach(([moduleId, value]) => {
      fields[moduleId] = value?.id;
    });
    
    setAlert(null);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name: data.name,
        description: data.description,
        fieldname: 'group_id',
        fields
      })
    }

    fetch(`/api/societies`, requestOptions)
      .then(res => {
        if (res.ok) {
          reset();
          res.json().then((data) => {
            setSocietyCreated(data)
          })
          props.handleClose();
          return;
        }

        return res.json().then((data) => {
          setAlert(data?.message || 'Une erreur est survenue');
        });
      })
      .catch(err => {
        setAlert('Une erreur est survenue');
      }).finally(() => {
      });
  }
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Typography id="modal-modal-title" variant="h6" sx={{ mb: 1 }}>
          Nouvelle société
        </Typography>
        <Collapse in={Boolean(alert)}>
          <Alert
            severity="error"
            variant="outlined"
            sx={{ py: 0, mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => { setAlert(null); }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert}
          </Alert>
        </Collapse>
        <Grid container justifyContent="space-evenly" spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Controller
                name="name"
                control={control}
                rules={{required: 'Le nom est requis'}}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    fullWidth
                    variant='outlined'
                    label='Nom'
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    minRows={4}
                    variant='outlined'
                    label='Description'
                  />
                )}/>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              {modules.map((module) => (
                <Controller
                  key={module.id}
                  name={`modules.${module.id}`}
                  control={control}
                  render={(props) => (
                    <EntitySelect
                      {...props}
                      module={module}
                    />
                  )}
                />
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              disabled={isSubmitting}
              variant="contained"
              type="submit"
              color="primary"
              size="large"
              endIcon={isSubmitting && <CircularProgress size={25} />}
              sx={{ borderRadius: 0.8 }}
            >
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
      </Box>
    </Modal>
  )
}