import * as React from 'react';
import Contracts from './Contracts';
import Proposals from './Proposals';
import Invoices from './Invoices';
import Orders from './Orders';
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import Common from "../Common";
import Divider from '@mui/material/Divider';

export default class Dolibarr extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
     contracts: [],
     invoices: [],
     orders: [],
     proposals: [],
     contractsError: null,
     invoicesError: null,
     ordersError: null,
     proposalsError: null,
     isLoading: false
    }
  }


  refreshModuleData(){
    this.setState({isLoading: true});
    fetch(`/api/societies/${this.props.societyId}/modules/dolibarr?refresh=true`)
    .then(res => res.json())
    .then((data) => {
      // Prevent state change when the component is unmounted
      this.setState({
        contracts: data.data.contracts?.error?.code === 404 ? [] : data.data.contracts ,
        proposals: data.data.proposals?.error?.code === 404 ? [] : data.data.proposals ,
        invoices: data.data.invoices?.error?.code === 404 ? [] : data.data.invoices,
        orders: data.data.orders?.error?.code === 404 ? [] : data.data.orders,
        contractsError : data.data.contracts?.error?.code !== 404 ? data.data.contracts.error : null,
        invoicesError : data.data.invoices?.error?.code !== 404 ? data.data.invoices.error : null,
        ordersError : data.data.orders?.error?.code !== 404 ? data.data.orders.error : null,
        proposalsError : data.data.proposals?.error?.code !== 404 ? data.data.proposals.error : null,
        module: {
          name: data.name,
          id: data.id,
          data: data.data.society
        }
      })

    })
    .finally(() => {
      this.setState({isLoading: false})
    })
  }

 

  componentDidMount() {
    this._isMounted = true
    const { props } = this;

    if (props.societyId) {
      if (!this._isMounted) { return; }      
      this.refreshModuleData(props);
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false
  }


  componentDidUpdate(previousProps) {
    if (this.props.societyId !== previousProps?.societyId) {
      this.refreshModuleData(this.props);
    }
  }

  render() {

    const {
      module,
      contracts,
      invoices,
      orders,
      proposals,
      contractsError,
      ordersError,
      invoicesError,
      proposalsError,
      isLoading
    } = this.state

    if (isLoading) {
      return (
        <Grid container direction='column' spacing={0}  justifyItems='center' alignItems='center'>
          <CircularProgress size={150} sx={{mx:'auto', my: 'auto'}}/>
        </Grid>
      )
    }

    return (
    <>
        <Box sx={{display: 'grid',gridAutoColumns: '1fr',gap: 1}}>
          <Box item  sx={{ gridRow: '1', gridColumn: 'span 3' }}>
            <Common module={module} societyId={this.props.societyId} name='dolibarr' onUpdate={() => {this.refreshModuleData()}}/>
          </Box>
        </Box>
        <Divider orientation='horizontal' variant='fullwidth' sx={{mt: 2, mb: 1}}/>
        {proposalsError ? (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
          Il y a eu un problème avec le chargement des contrats de la société. Contactez l'administrateur.
        </Alert>) : (<Proposals proposals={proposals}/>)}
      {contractsError ? (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
          Il y a eu un problème avec le chargement des contrats de la société. Contactez l'administrateur.
        </Alert>) : (<Contracts contracts={contracts}/>)}
      {invoicesError ? (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
        Il y a eu un problème avec le chargement des factures de la société. Contactez l'administrateur.
      </Alert>) : (<Invoices invoices={invoices}/>)}
      {ordersError ? (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
        Il y a eu un problème avec le chargement des commandes de la société. Contactez l'administrateur.
      </Alert>) : (<Orders orders={orders}/>)}
    </>
  )};
}
