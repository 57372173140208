import * as React from 'react';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';
import { Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer } from "@mui/material";
import Link from '@mui/material/Link';
import TablePagination from '@mui/material/TablePagination';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';


const renderProposal = (proposal) => {


    const servicesCounter = proposal?.lines.reduce((acc, item) => {

        const counter = acc.get(item.ref);

        if (counter?.count) {
            counter.count += 1;
        } else {
            acc.set(item.ref, { count: 1, item });
        }
        return acc;
    }, new Map());
    console.log(proposal)
    const dateProposal = new Date(proposal.datep * 1000);
    const dateValidation = proposal.datev ? new Date(proposal?.datev * 1000) : null;
    const services = Array.from(servicesCounter.entries()).map(([service, item]) => (<ListItem key={service}>{item.count} service(s) {service} ({item?.item?.product_label})</ListItem>))
    return (
        <TableRow key={proposal.id}>
            <TableCell align="center">
                <Link underline="hover" href={`${process.env.REACT_APP_DOLIBARR_URL}/comm/propal/card.php?id=${proposal?.id}`}>
                    {proposal.ref}
                </Link>
            </TableCell>
            <TableCell align="center"><List>{services}</List></TableCell>
            <TableCell align="center">{dateProposal.toLocaleDateString('fr-Fr')}</TableCell>
            <TableCell align="center">{dateValidation ? dateValidation.toLocaleDateString('fr-Fr') : ""}</TableCell>
            <TableCell align="center"> {renderStatus(proposal)}</TableCell>
        </TableRow>
    );
}


function renderStatus(proposal) {
    const now = Date.now();
    const validityEnd = new Date(proposal.fin_validite * 1000);
    const signatureDate = proposal.date_validation ? new Date(proposal.date_validation * 1000) : null;
    const closeDate = new Date(proposal.datec * 1000);
    const daysBeforeProposalEnd = Math.floor((validityEnd - now) / (24 * 60 * 60 * 1000));
    if (signatureDate){
        if (closeDate){
            return <Typography color="green">Signée et facturée</Typography>
        } else {
            if (daysBeforeProposalEnd <= 0){
                return <Typography color="lightgrey">Refusée</Typography>
            } else {
                return <Typography color="orange">Ouverte</Typography>
            }
        }
    } else {
        if(daysBeforeProposalEnd <= 0){
            if (closeDate) {
                return <Typography color="orange">Validée (ouverte)</Typography>

            }
            return <Typography color="lightgrey">Non signée (fermée)</Typography>
        }else {
            return <Typography color="orange">Ouverte</Typography>
        }
    }
}

export default function Proposal(props) {
    const { proposals } = props;
    let proposalsList;
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const sortByDate = (a, b) => a?.date < b?.date ? 1 : -1

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    if (proposals?.length > 0) {
        proposalsList = proposals
            ?.sort?.(sortByDate)
            ?.slice?.(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ?.map?.((proposal) => renderProposal(proposal))
    }
    else {
        proposalsList = <TableRow><TableCell align="center" colSpan={5}>Aucune proposition en cours</TableCell></TableRow>
    }

    return (
        <>
            <Typography variant="body1" color="text.secondary">
                Propositions
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 3, mt: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nom</TableCell>
                            <TableCell align="center">Services</TableCell>
                            <TableCell align="center">Date proposition</TableCell>
                            <TableCell align="center">Date signature</TableCell>
                            <TableCell align="center">Statut</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {proposalsList}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 100]}
                component="div"
                count={proposals?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage='Nombre de lignes par page'
            />
            <Divider orientation='horizontal' variant='fullwidth' sx={{ mt: 2, mb: 1 }} />
        </>
    );
}
