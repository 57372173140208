import * as React from 'react';

import Typography from "@mui/material/Typography";
import { Card, CardContent } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import WorkIcon from '@mui/icons-material/Work';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';


export default function Contact(props) {
  const contact = props.data
  return (
    <Card sx={{mt:2, mr:1}}>
        <CardContent>
        <Typography color="text.secondary" variant="h6">
            <Link underline="hover" href={`${process.env.REACT_APP_DOLIBARR_URL}/contact/card.php?id=${contact.id}`}> 
                {contact.civility} {contact.lastname} {contact.firstname}
            </Link>
        </Typography>
        {contact?.categories?.map?.((category) => (
            <Chip key={category.id} label={category?.label} size='small' sx={{borderRadius: 0.5, bgcolor: `#${category?.color}`, mr:1}}/>
        ))}
        <List>
            <ListItem>
                <ListItemAvatar>
                    <EmailIcon color="primary" sx={{fontSize:"25"}}/>
                </ListItemAvatar>
                <ListItemText secondary="Email" primary={contact.email} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <WorkIcon color="primary" sx={{fontSize:"25"}}/>
                </ListItemAvatar>
                <ListItemText secondary="Téléphone pro." primary={contact.phone_pro} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <PhoneAndroidIcon color="primary" sx={{fontSize:"25"}}/>
                </ListItemAvatar>
                <ListItemText secondary="Mobile" primary={contact.phone_mobile} />
            </ListItem>
         </List>   

        </CardContent>
    </Card>
  );
}
