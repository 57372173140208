import { useEffect, useState } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Layout from './components/Layout'
import CircularProgress from '@mui/material/CircularProgress';
import Container from "@mui/material/Container";
import Stack from '@mui/material/Stack';

import SocietiesPage from './pages/index';
import SupportPage from './pages/support';
import SocietyPage from './pages/societies/[id]';
import MessengerPage from './pages/messenger';
import Typography from "@mui/material/Typography";

async function fetchSession() {
  // Timer volontaire pour tester la page de transition
  // await new Promise(resolve => setTimeout(resolve, 8000));

  const res = await fetch('/api/session');
  
  if (res.status === 401) {
    window.location = `/api/authenticate?origin=${encodeURIComponent(window.location)}`;
    return;
  }

  const session = await res.json();
  return session;
}

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    fetchSession().then(s => { setSession(s); });
  }, []);
  
  if (!session) {
    return (
      <Container sx={{bgcolor: 'primary', display: 'flex', alignItems: 'center', justifyContent: 'center', height:'100vh'}}>
        <Stack spacing={2} sx={{alignItems: 'center', justifyContent: 'center'}}>
          <Typography variant='h2'>BeePanel</Typography>
          <CircularProgress size={150}/>
        </Stack>
      </Container>
    )
  }

  return (
    <BrowserRouter>
      <Layout  session={session}>
        <Routes>
          <Route exact path="/" element={<SocietiesPage />} />
          <Route exact path="/support" element={<SupportPage />} />
          <Route exact path="/societies/:id" element={<SocietyPage />} />
          <Route exact path="/messenger" element={<MessengerPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
