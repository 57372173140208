import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import {Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';

export default function SupportSelector(props) {
  const {
    setSupportType,
    supportType,
  } = props;
  
  
  let supportTypes = [
    {'name': 'Support Client Zimbra', 'value': '4420'},
    {'name': 'Ceph', 'value': '4428'},
    {'name': 'Support Vadesecure', 'value': '4425'},
    {'name': 'Tous', 'value': '4420,4428,4425'},
    {'name': 'Projet de test', 'value': 'test'}
  ]

  return (
    <Grid container>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="support-id">Type de support</InputLabel>
          <NativeSelect
            value={supportType}
            name="support_id"
            inputProps={{ id:'support-id' }}
            onInput={(event) => (setSupportType(event.target.value))}
          >
            {supportTypes.map((support) => (
              <option value={support.value} key={support.value}>{support.name}</option> 
            ))}
          </NativeSelect>
        </FormControl>
      </Grid>
    </Grid>
  )
}
