import * as React from 'react';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Common from "./Common";
import UsersList from "./UsersList";
import DifferencesList from './Differences';
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import {Tab, Tabs} from '@mui/material';

import {Paper, Table, TableHead, TableBody, TableCell, TableRow, TableContainer, } from "@mui/material";
import Alert from '@mui/material/Alert';
import { red } from '@mui/material/colors';

const url = "https://projets.beezim.fr"

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

const renderIssues = (issues, project_id) => {
  return issues?.map?.((issue) => (
    <TableRow key={issue.identifier}>
      <TableCell align="center">{ toTitleCase(issue.name) }</TableCell>
      <TableCell align="center">
        <a href={`${url}/projects/${project_id}/issues?set_filter=1&tracker_id=${issue.identifier}`}>{issue.count}</a>
      </TableCell>
    </TableRow>
  ))
}


function TabProject(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabproject"
      hidden={value !== index}
      id={`simple-tabproject-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default class Redmine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      module: {},
      projectId: null,
      issues: [],
      currentProject: [],
      differencesStatus: {},
      issuesError: null,
      projectError: null,
      differencesError: null,
      value: 0
    };
  }
  updateIssues(newProjectId) {
    fetch(`/api/modules/redmine/projects/${newProjectId}`)
      .then(res => res.json())
      .then((data) => {
          this.setState({
            issues: data,
            projectId: newProjectId,
          })
      })
      .catch((err) => {
        this.setState({
          issuesError: err
        })
      })    
  }

  getDifferences(society_id) {
    fetch(`/api/societies/${society_id}/modules/redmine/update`)
    .then(res => res.json())
    .then((data) => {
      this.setState({
        differencesStatus: data,
      })
    })
    .catch((err) => {
      this.setState({
        differencesError: err
      })
    })    
  }

  componentDidMount() {
    this._isMounted = true

    if (this.props.societyId) {
      this.refreshModuleData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refreshModuleData(opt){
    const societyId = this.props.societyId;
    const refresh = opt?.refresh;

    fetch(`/api/societies/${societyId}/modules/redmine${refresh ? '?refresh=true' : ''}`)
      .then(res => res.json())
      .then((data) => {
        // Prevent state change when the component is unmounted
        if (!this._isMounted) { return; }
        
        this.getDifferences(societyId)
        this.setState({ module: data, currentProject: data });
        
        const mainProjectId = data?.data?.mainProjectId;
        
        if (mainProjectId) {
          this.updateIssues(mainProjectId);
        }
      })
      .catch((err) => {
        this.setState({
          projectError: err,
          module: {}
        })
      })    
  }

  componentDidUpdate(previousProps) {
    if (this.props.societyId !== previousProps?.societyId) {
      this.refreshModuleData();
    }
  }

  handleChange(event, newValue){
    this.setState({ value: newValue })
  };

  handleClick(newProject){
    const id = newProject?.data?.id ? newProject?.data?.id : newProject?.id
    this.updateIssues(id);
    this.setState({ 
      currentProject: newProject,
    })
  }


  a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabproject-${index}`,
    };
  }

  render() {
    const {
      module,
      issues,
      projectId,
      differencesStatus,
      projectError,
      issuesError,
      differencesError,
      currentProject,
      value, 
    } = this.state
    
    let projects = [];
    const mainProject = module?.data;
    const subprojects = module?.data?.subprojects;

    if (mainProject) {
      projects = [mainProject];
      
      if (Array.isArray(subprojects)) {
        projects = [...projects, ...subprojects];
      }
    }

    return (
      <>
        <Box sx={{display: 'grid',gridAutoColumns: '1fr',gap: 1}}>
          <Box item  sx={{ gridRow: '1', gridColumn: 'span 3' }}>
            <Common module={module} societyId={this.props.societyId} name='redmine' onUpdate={() => {this.refreshModuleData()}}/>
          </Box>
        </Box>
        {(differencesStatus.error || differencesError?.error) ? 
          (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
            Il y a eu un problème avec le chargement des différences avec Dolibarr, de la société. Contactez l'administrateur.
          </Alert>) : 
          (<DifferencesList society={differencesStatus} module='Redmine'/>)
        }
        <Divider orientation='horizontal' variant='middle' sx={{mt: 2, mb: 1}}/>
        <Typography variant="body2" color="text.secondary">
            Projets
        </Typography>
        <Tabs
            sx={{ flexGrow: 1 }}
            value={value}
            onChange={(evt, newValue) => this.handleChange(evt, newValue)}
          >
            {projects?.map((subproject, index) => (
              <Tab
                onClick={() => this.handleClick(subproject)}
                label={subproject?.name}
                key={subproject?.id}
                name={subproject?.name}
                {...this.a11yProps(index + 1)}
              />
            ))}

        </Tabs>
        { projects.map((project, index) => (
          <TabProject  value={value} index={index} key={index}>
          {projectError ? 
            (<></>) : 
            (<>
              <UsersList data={currentProject} url={`${process.env.REACT_APP_REDMINE_URL}/users`}/>
              <Divider orientation='horizontal' variant='middle' sx={{mt: 2, mb: 1}}/>
              <Typography variant="body2" color="text.secondary">
                  Tickets (Projet #{projectId})
              </Typography>
              <TableContainer component={Paper} sx={{mb:3, mt:2}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Catégorie</TableCell>
                      <TableCell align="center">Nombre</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {(issues.error || issuesError?.error) ? 
                  (<Alert severity="error" variant='filled' sx={{mb:1, bgcolor: red[300]}}>
                    Il y a eu un problème avec le chargement des issues de la société. Contactez l'administrateur.
                  </Alert>) :
                  (renderIssues(issues, projectId))
                  }
                    
                  </TableBody>
                </Table>
              </TableContainer>
            </>)
          }
        </TabProject>

        ))}
        

      </>
    );
  }
}
