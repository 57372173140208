import * as React from 'react';

import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';

export default function IssuesType(props) {
  const {
    trackerType,
    setTrackerType,
    status,
    setStatus,
    priority,
    setPriority,
    parentId,
    setParentId,
    assignedTo,
    setAssignedTo,
  } = props;

  return (
    <Grid container columnSpacing={3}>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="tracker-id">Tracker</InputLabel>
          <NativeSelect value={trackerType} name='tracker_id' inputProps={{ id: "tracker-id" }} onInput={(event) => setTrackerType(event.target.value)}>
            <option value={1}>Bug</option>
            <option value={2}>Feature</option>
            <option value={3}>Support</option>
            <option value={4}>Task</option>
            <option value={5}>Projet</option>
          </NativeSelect>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="status-id">Statut</InputLabel>
          <NativeSelect name='status_id' value={status} inputProps={{ id: "status-id" }} onInput={(event) => setStatus(event.target.value)}>
            <option value={1}>New</option>
            <option value={8}>Todo</option>
            <option value={2}>In progress</option>
            <option value={4}>Feedback Client</option>
            <option value={13}>Feedback Editeur</option>
          </NativeSelect>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="priority">Priorité</InputLabel>
          <NativeSelect name='priority_id' value={priority} inputProps={{ id: "priority-id" }} onInput={(event) => setPriority(event.target.value)}>
            <option value={4}>Normale</option>
            <option value={3}>Lente</option>
            <option value={5}>Rapide</option>
            <option value={6}>Urgente</option>
          </NativeSelect>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="parent-id">Projet parent</InputLabel>
          <NativeSelect name='parent-id' value={parentId} inputProps={{ id: "parent_id" }} onInput={(event) => setParentId(event.target.value)}>
            <option value={14} >Clients</option>
          </NativeSelect>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth>
          <InputLabel variant="standard" htmlFor="assigned-to-id">Assigné à</InputLabel>
          <NativeSelect name='assigned_to_id' value={assignedTo} inputProps={{ id: "assigned-to-id" }} onInput={(event) => setAssignedTo(event.target.value)}>
            <option value={12}>Hadrien</option>
            <option value={6}>Florent</option>
            <option value={197}>Loïc</option>
            <option value={278}>Elise</option>
            <option value={270}>Thibault</option>
          </NativeSelect>
        </FormControl>
      </Grid>
    </Grid>
  )
}