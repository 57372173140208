import Alert from '@mui/material/Alert';

export default function DifferencesList(props) {
    const society = props.society
    const module = props.module
    return Object.keys(society).map?.((field, index) => {
        if (society[field]?.status !== "ok" ) {
          return (
            <Alert severity="warning" key={index} sx={{my: 1}}>  
            {society[field]?.status}
          </Alert>
          )
        }
        else if (society[field]?.status === "ok"){
          return (
            <Alert severity="success" key={index} sx={{my: 1}}>  
              Le champ &quot;{field}&quot; de la société est similaire entre {module} et Dolibarr.
            </Alert> 
          )
        }
    
        return (
          <Alert severity="error" key={index} sx={{my: 1}}>  
            Problème de comparaison du champ &quot;{field}&quot; entre {module} et Dolibarr.
          </Alert> 
        )
      })
}