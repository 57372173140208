import * as React from "react";
import { Box, Tab, Tabs } from '@mui/material';
import Gitlab from './modules/Gitlab';
import Redmine from './modules/Redmine';
import Dokuwiki from './modules/Dokuwiki';
import Dolibarr from './modules/dolibarr/Dolibarr';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import AddModuleSocietyMapping from './AddModuleSocietyMapping';



const ModuleContent = (props, ref) => {
  const { module, societyId, setStatus, innerRef } = props;

  switch (module?.id) {
    case 'redmine':
      return (<Redmine ref={innerRef} societyId={societyId} />);
    case 'gitlab':
      return (<Gitlab ref={innerRef} societyId={societyId} />);
    case 'dokuwiki':
      return (<Dokuwiki ref={innerRef} societyId={societyId} />);
    case 'dolibarr':
      return (<Dolibarr ref={innerRef} societyId={societyId} setStatus={setStatus} />);
    default:
      return;
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function Modules({ modules, society_id, setStatus, refreshSociety }) {
  const [value, setValue] = React.useState(0);
  let moduleRef = React.createRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { refreshSociety(true); setOpen(false)};


  function refreshSelectedModule() {
    moduleRef.current.refreshModuleData()
  }

  const handleClick = (index) => {
    console.log(modules[index])
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}>
          <Tabs
            sx={{ flexGrow: 1 }}
            value={value}
            onChange={handleChange}
          >
            {modules?.map?.((module, index) => (
              <Tab
                onClick={() => handleClick(index)}
                label={module.name}
                key={module.id}
                name={module.name}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          <IconButton
            variant="contained"
            sx={{ '&:hover': { bgcolor: grey[600], color: "white" }, flexShrink: 0, my: 1, mx: 1 }}
            onClick={handleOpen}
          >
            <AddIcon />
          </IconButton>
          <Button
            sx={{ flexShrink: 1, my: 1 }}
            onClick={refreshSelectedModule}
            variant="contained"
          >
            Actualiser
          </Button>
        </Box>
      </Grid>
      <AddModuleSocietyMapping open={open} handleClose={handleClose} societyId={society_id}/>
      <Grid item xs={12} md={12} lg={12}>
        {modules?.map?.((module, index) => (
          <TabPanel value={value} index={index} key={index}>
            <ModuleContent
              innerRef={value === index ? moduleRef : null}
              module={module}
              societyId={society_id}
              setStatus={setStatus}
            />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  )
}

export default Modules;