import * as React from 'react';

import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from "@mui/material/IconButton";
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import { Box, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';

export default function Common(props) {
  const module = props.module;
  const moduleName = props.name;
  const societyId = props.societyId;
  const handleUpdate = props.onUpdate;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [value, setValue] = React.useState('');
  const [alert, setAlert] = React.useState(null)
  
  const handleClick = (event) => {
    setValue('');
    setAlert('');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!value) { return; }

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({'name': `${moduleName}_group_id`, 'value': value})
    }
    fetch(`/api/fields/${module.id}/societies/${societyId}/group_id`, requestOptions)
    .then(res => {
      if (!res.ok) {
        const err = new Error(res.statusText);
        err.notFound = res?.status === 404;
        throw err;
      }
      return res.json();
    })
    .then(() => {
      handleUpdate();
      handleClose();
    })
    .catch((err) => {
      setAlert(err?.notFound ? 'Société non trouvée' : 'Une erreur est survenue');
    })
  }
  return (
    <>
      <Typography variant="body2" color="text.secondary">
        Nom de l'entité : {module?.data?.name}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Identifiant de l'entité dans le module : {module?.data?.identifier ? module.data.identifier : module?.data?.id } 
        
        <IconButton size='small' sx={{mx: 0.5}} onClick={handleClick}>
          <EditIcon fontSize='25'/>
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          >
            <Stack spacing={0.5} sx={{ p: 0.5 }}>
              <Collapse in={Boolean(alert)}>
                <Alert
                  severity="error"
                  variant="outlined"
                  sx={{ py: 0 }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => { setAlert(null); }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {alert}
                </Alert>
              </Collapse>

              <Box component="form" onSubmit={handleSubmit} display="flex" alignItems="center" >
                <TextField
                  autoFocus
                  id="value"
                  type="string"
                  size="small"
                  value={value}
                  onInput={(event) => setValue(event.target.value)}
                  sx={{ maxWidth: 150, mr: 0.5 }}
                />
                <IconButton
                  variant="contained"
                  type="submit"
                  color="secondary"
                  size="small"
                  disabled={!value}
                  sx={{
                    backgroundColor: blue[500],
                    borderRadius:0.8,
                    '&:hover': {
                      backgroundColor: blue[300]
                    }
                  }}
                >
                  <DoneIcon fontSize="25" />
                </IconButton>
              </Box>
            </Stack>
        </Popover>
      </Typography>
    </>
  );
}
