import {useState, useEffect, useRef} from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import { Grid } from '@mui/material';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Society from './Society';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  borderRadius: 1,
  boxShadow: 24,
  p: 2,
};

export default function AddModuleSocietyMapping(props) {
  const societyId = props.societyId;
  const [selectedModule, setSelectedModule] = useState('');
  const [selectedProject, setSelectedEntity] = useState('');
  const [avalaibleEntities, setAvailableEntities] = useState([]);
  const [avalaibleEntitiesError, setAvailableEntitiesError] = useState(null);
  const [alert, setAlert] = useState(null)
  const [submitting, setSubmitting] = useState(false);
  const isMounted = useRef(false);    

  useEffect(() => {
    isMounted.current = true;

    return function cleanup () {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.open) {
      setSelectedModule('');
      setSelectedEntity('');
    }
  }, [props.open]);

  const handleSelectedModule = (event) => {
    const moduleName = event.target.value
    setSelectedModule(moduleName);
    fetch(`/api/modules/${moduleName}/entities`)
      .then(res => res.json())
      .then((data) => {
        if (isMounted.current){
          setAvailableEntities(data);
        }
      })
      .catch((err) => {
        setAvailableEntitiesError(err)
        setAlert(`Une erreur est survenue.`)
      })
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            projectId: selectedProject,
        })
    }

    fetch(`/api/fields/${selectedModule}/societies/${societyId}/group_id`, requestOptions)
    .then(res => {
      if (res.ok) {
        props.handleClose();
        setSelectedModule('');
        setSelectedEntity('');
        return;
      }

      return res.json().then((data) => {
        setAlert(data?.message || 'Une erreur est survenue');
      });
    })
    .catch(err => { 
      setAlert('Une erreur est survenue');
    }).finally(() => {
      setSubmitting(false);
    });
  }
  const handleSelectedEntity = (event) => {
    setSelectedEntity(event.target.value);
  };

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} onSubmit={handleSubmit} component='form'>
        <Typography id="modal-modal-title" variant="h6" sx={{ mb: 1 }}>
          Mapping avec un nouveau module
        </Typography>
        <Collapse in={Boolean(alert)}>
          <Alert
            severity="error"
            variant="outlined"
            sx={{ py: 0, mb: 2 }}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => { setAlert(null); }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alert}
          </Alert>
        </Collapse>
        <Grid container columnSpacing={2} alignItems="center">
          <Grid item lg={3} md={3} sm={3} >
            <FormControl fullWidth>
              <InputLabel id="module_id">Module</InputLabel>
              <Select
                labelId="module_id"
                id="demo-simple-select"
                value={selectedModule}
                label="Module"
                onChange={handleSelectedModule}
              >
                <MenuItem value={"redmine"}>Redmine</MenuItem>
                <MenuItem value={"dolibarr"}>Dolibarr</MenuItem>
                <MenuItem value={"gitlab"}>Gitlab</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={7} md={7} sm={7} >
            <FormControl fullWidth>
              <InputLabel id="project_id">Entité</InputLabel>
              <Select
                labelId="project_id"
                id="demo-simple-select"
                value={selectedProject}
                label="Module"
                disabled={!selectedModule || avalaibleEntitiesError}
                onChange={handleSelectedEntity}
              >
                {avalaibleEntities.map((entity) => (
                  <MenuItem key={entity.id} value={entity?.identifier ? entity?.identifier : entity?.id}>{entity.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={1} md={1} sm={1}>
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              size="large"
              disabled={!selectedProject}
              endIcon={submitting ? <CircularProgress size={25}/> : <></>}
              sx={{
                backgroundColor: blue[500],
                color: 'white',
                borderRadius: 0.8,
                '&:hover': {
                  backgroundColor: blue[300]
                }
              }}
              onClick={handleSubmit}
            >Ajouter</Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}